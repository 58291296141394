import React from "react";
import "./../App.css";


const Squad = (props) => {

    const connect = ()=>{
        props.connectButton();
    }

	return (
        <div>
        { props.squad.length ? (

          <section id="team">
      		  <div className="container">
      			<div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
      			  <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Your personal army of {props.squad.length} frogs - "{props.squad.length>=50?"Whaling it":props.squad.length>=10?"Whale-to-be":props.squad.length<10?"Collectoor":""}"</h3>
      			  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Your Squad:</h2>
      			</div>
      			<div className="cs-height_50 cs-height_lg_30"></div>
      			<div className="row text-center" data-wow-duration="1s" data-wow-delay="0.4s">
            {props.squad.map((squaddie, index) => (
              <div key={squaddie.escapeeNo} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div className="cs-team cs-style1 text-center cs-white_bg">
                  <div className="cs-height_30 cs-height_lg_30"></div>
                  <div className="cs-member_thumb">
                    <a href={squaddie.opensea} rel="noreferrer" target="_blank">
                      <img  src={squaddie.image} alt="squad member" />
                      <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">{squaddie.escapeeNo}</div>
                      { 
                      squaddie.sacraficeCount > 0 && (
                        <span className="burn-count">🔥 {squaddie.sacraficeCount} </span>
                      ) 
                     }
                    </a>
                  </div>
                  <div className="cs-member_info"> <div className="cs-height_30 cs-height_lg_30"></div></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

        ):(
            <div>
                <section id="team">
		            <div className="container">


                    <div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                    {/*<pre>{JSON.stringify(props,null,4)}</pre>*/}
                    <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Squad Check... {props.loaded?props.hasSquad?"Loading squad members":"No squad? - sad face.":"Checking for Squad"}</h3>

                    { !props.loaded ? (
                        <div>

                            <div>
                            { !props.currentAccount ? (

                                <a
                                className="connect-btn"
                                href={
                                    window.ethereum
                                        ? "#"
                                        : "https://alcatrazswimteam.com"
                                }
                                onClick={props.connectButton}
                                data-aos="zoom-in"
                                >
                                <button className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"  >{props.currentAccount ? "Connected" : "Connect Wallet To check Squad"}</button>
                                </a>

                            ):(
                                <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Checking Squad....</h2>
                            )}
                            </div>
                        </div>
                    ):(


                        <div>
                            <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">{props.loaded?props.hasSquad?"Loading Squad Members....":"No Squad Found":""}</h2>
                            <div>
                            { (props.loaded && !props.hasSquad) ? (
                                <div>
                                    <div className="cs-height_50 cs-height_lg_10"></div>
                                    <a href="#" className="cs-btn cs-btn_filed cs-white_btn cs-modal_btn" data-modal="mint-main"><span>Mint Now</span></a>
                                </div>
                            ):(
                                <span></span>
                            )}
                            </div>
                        </div>


                    )}

                    </div>
                    </div>
                </section>
            </div>
	    )}
    </div>
    )
};
export default Squad;
