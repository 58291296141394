import React, { useState, useEffect } from "react";
import "./../App.css";


const BurnToEarn = (props) => {
    const [selectedburns, setSelectedburns] = useState([]);
    const [selectednoms, setSelectednoms] = useState([]);
    const [showBurnToEarn, setShowBurnToEarn] = useState(false)


    const addToSelectedNoms = function(id){
        
        console.log(id)
         var hasEntry = false; 
         var existingIndex = 0;
         console.log(selectednoms)
        for (let i = 0; i < selectednoms.length; i++) {
         
         if(selectednoms[i]== id){
          hasEntry = true;
          existingIndex = i;
         }
        }
        if(!hasEntry){
         setSelectednoms([...selectednoms,id])
        } else {
         console.log('I should remove this it is index'+ existingIndex );
 
         const burnsCopy = [...selectednoms];
          burnsCopy.splice(existingIndex,1) 
         console.log(burnsCopy);
         setSelectednoms(burnsCopy)
        }
     }

    const addToSelectedBurns = function(id){
        
       console.log(id)
        var hasEntry = false; 
        var existingIndex = 0;
        console.log(selectedburns)
       for (let i = 0; i < selectedburns.length; i++) {
        
        if(selectedburns[i]== id){
         hasEntry = true;
         existingIndex = i;
        }
       }
       if(!hasEntry){
        setSelectedburns([...selectedburns,id])
       } else {
        console.log('I should remove this it is index'+ existingIndex );

        const burnsCopy = [...selectedburns];
         burnsCopy.splice(existingIndex,1)
        console.log(burnsCopy);
        setSelectedburns(burnsCopy)
       }
    }


    const isSelected = function(id){
        
         var hasEntry = false; 
        for (let i = 0; i < selectedburns.length; i++) { 
         if(selectedburns[i] === id){
          hasEntry = true;
         }
        }
        if(hasEntry){
            return true;
        }
        return false;
     }

     const isNom = function(id){
        
        var hasEntry = false; 
       for (let i = 0; i < selectednoms.length; i++) { 
        if(selectednoms[i] === id){
         hasEntry = true;
        }
       }
       if(hasEntry){
           return true;
       }
       return false;
    }

    const isLegalBurn = function(){
        var isLegal = true;

        if(!(selectedburns.length && selectednoms.length)){
            isLegal = false;
        }

        if(!(selectedburns.length >= selectednoms.length)){
            isLegal = false;
        }

        if((selectedburns.length % selectednoms.length)){
            isLegal = false;
        }

        return isLegal;

       
    }


    React.useEffect(() => {

        window.addEventListener('burnComplete', (event) => {
        console.log('burnComplete');
         setSelectednoms([]);
         setSelectedburns([]);
    
        });
    
      }, []);
    

	return (
        <section id="burn-to-earn">
        <div className="cs-height_30 cs-height_lg_20"></div>
		<div className="container">
            { props.squad.length > 0 &&(
            <div>
            <h1>Burn To Earn  
                { !showBurnToEarn  && (
                 <button className="cs-btn cs-btn_filed cs-white_btn_2" onClick={()=>{setShowBurnToEarn(true)}}>Start</button>
               
                )}
               
                </h1>

            
            
            { showBurnToEarn && (
            <div>
            <p>Burning pepes from your squad, while nominating a pepe from your squad enables you to build sacrafic/burn points against the nominated pepe, This both serves as a way to track burn ritual activity while also providing a unique way to build chain driven data points.</p>
            <p><strong>Step 1:</strong> Select pepes from your squad to burn (click to select, you can select multiple)</p>
           
              
            <ul className="burn-selector">
            {
                props.squad.map((squaddie, index) => (
                    <li key={squaddie.escapeeNo}>
                        {!isNom(squaddie.escapeeNo) && (
                         <img className={isSelected(squaddie.escapeeNo)?'selected-pepe':''} onClick={()=>{addToSelectedBurns(squaddie.escapeeNo)}}  src={squaddie.image} alt="squad member" />
                        )}
                        </li>
                ))
            }
            </ul>
            { selectedburns.length > 0 && (
            <div>
                <p><strong>Step 2:</strong> Nominate pepe</p>
                <ul className="burn-selector nominees">
                {
                    props.squad.map((squaddie, index) => (
                        <li key={squaddie.escapeeNo}>
                            {!isSelected(squaddie.escapeeNo) && (
                            <img className={isNom(squaddie.escapeeNo)?'selected-pepe':''} onClick={()=>{addToSelectedNoms(squaddie.escapeeNo)}}  src={squaddie.image} alt="squad member" />
                            )}
                        </li>
                    ))
                }
                
                </ul>
            </div>
            )}
        <div>
            { isLegalBurn() && (
                <div>
                    <p><strong>Step 3:</strong> Commence the burn</p>
                {/* <p>Burn:[<span>
                    { selectedburns.map((burn,index) => (
                        <span key={index}>{burn} </span>
                    ))}
                    </span>] and nominate:[<span>
                    { selectednoms.map((nom,index) => (
                        <span key={index}>{nom} </span>
                    ))}
                    </span>]</p> */}
                
                    <p>
                        <button className="cs-btn cs-btn_filed cs-white_btn_2" disabled={props.isBurning} onClick={()=>{props.burnToEarnTx(selectedburns, selectednoms)}}>️‍🔥 Burn {selectedburns.length} Swimmers, and Nominate {selectednoms.length}</button>
                    </p>
                </div>
            )}
        </div>
        </div>
        )}
        </div>
        
        
          )
        }
        <div className={props.isBurning?'section-busy':''}></div>
        
        </div>
        
        </section>
    )
};
export default BurnToEarn;
